<template>
    <div>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#3F033B"
        spinner="bar-fade-scale"
      />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
  
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #000">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap>
        <v-flex xs12 py-2 text-left>
          <v-layout wrap>
            <v-flex xs12 pb-2 text-left class="mainHeader">Payment History</v-flex>
          <!-- <v-flex xs2 class="tst3">Balance Thankam :</v-flex> -->
          </v-layout>
          <v-layout wrap v-if="settledList">
            <v-flex xs12 v-if="settledList.length>0">
              <v-card elevation="0"
            tile
            :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
            :flat="$route.name == 'Dashboard' ? true : false"
          >
          <v-layout wrap pa-4 >
              <v-flex xs12>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                          <b>S.No.</b>
                        </th>
                        <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                          <b>SETTLED_DATE</b>
                        </th>
                        <!-- <th
                          class="text-left subhed"
                          style="font-size: 15px; color: black"
                        >
                          <b>DESIGN</b>
                        </th>
                        <th
                          class="text-left subhed"
                          style="font-size: 15px; color: black"
                        >
                          <b>COUNT</b>
                        </th>
                        <th
                          class="text-left subhed"
                          style="font-size: 15px; color: black"
                        >
                          <b>ITEM/WT</b>
                        </th>
                        <th
                          class="text-left subhed"
                          style="font-size: 15px; color: black"
                        >
                          <b>NET.WT</b>
                        </th>
                        <th
                          class="text-left subhed"
                          style="font-size: 15px; color: black"
                        >
                          <b>COST</b>
                        </th>
                        <th
                          class="text-left subhed"
                          style="font-size: 15px; color: black"
                        >
                          <b>STONE PRICE</b>
                        </th>
                        <th
                          class="text-left subhed"
                          style="font-size: 15px; color: black"
                        >
                          <b>STONE WT</b>
                        </th> -->
                        <!-- <th
                          class="text-left subhed"
                          style="font-size: 15px; color: black"
                        >
                          <b>TOTAL(Rs.)</b>
                        </th> -->
                        <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                          <b>THANKAM RATE</b>
                        </th>
                        <!-- <th
                          class="text-left subhed"
                          style="font-size: 15px; color: black"
                        >
                          <b>TOUCH RATE</b>
                        </th> -->
                        <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                          <b>PAYMENT TYPE</b>
                        </th>
                        <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                          <b>PAY AMOUNT (Rs.)</b>
                        </th>
                        <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                          <b>PURCHASED THANKAM</b>
                        </th>
                       
                      
                        <th
                          class="text-left tablefont"
                          style="font-size: 15px; color: black"
                        >
                          <b>BALANCE THANKAM</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                          <tr
                            v-for="(value, i) in settledList"
                            :key="i"
                            class="table"
                          >
                            <td>
                              <span>
                                {{ i + 1 }}
                              </span>
                            </td>
                            <td>
                              <span v-if="value">{{
                                formatDate(value.create_date)
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <!-- <td>
                              <span v-if="value.supplierDesignCode">{{
                                value.supplierDesignCode
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.count"> {{ value.count }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.itemPerWeight">{{
                                value.itemPerWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.netWeight">{{
                                value.netWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.cost">{{ value.cost }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.stonePrice">{{
                                value.stonePrice
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.stoneWeight">{{
                                value.stoneWeight
                              }}</span>
                              <span v-else>-</span>
                            </td> -->
                            <!-- <td>
                              <span v-if="value.taxableAmount">{{
                                value.taxableAmount
                              }}</span>
                              <span v-else>-</span>
                            </td> -->
                            <td>
                              <span v-if="value">{{
                                value.thankamRate
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <!-- <td>
                              <span v-if="value.touchRate">{{
                                value.touchRate
                              }}</span>
                              <span v-else>-</span>
                            </td> -->
                            <td>
                              <span v-if="value">{{value.paymentType[0]}}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value">{{
                                value.payAmount
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value">{{
                                (value.totalThankamWeight).toFixed(2)
                              }}</span>
                              <span v-else>-</span>
                            </td>
                           
                        
                            
                            <td>
                              <span v-if="value">{{
                                (value.balanceThankam).toFixed(2)
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            
                          </tr>
                        </tbody>
                  </template>
                </v-simple-table>
              </v-flex>
            </v-layout>
  </v-card>
            </v-flex>
            <v-flex v-else >
              <v-card
            tile elevation="0"
            :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
            :flat="$route.name == 'Dashboard' ? true : false"
          >
          <v-layout wrap pa-4 >
              <v-flex xs12 text-center class="tablefont1">
                No history found
                </v-flex>
                </v-layout>
                </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
    <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showsnackbar: false,
        timeout: 2000,
        msg: null,
        page: 1,
        Pagelength: null,
        settledList:[],
      };
    },
    mounted() {
      this.getSettledList();
    },
    computed: {
      appUser() {
        return this.$store.state.userData;
      },
    },
    methods: {
      getSettledList() {
        this.appLoading = true;
        axios({
          method: "POST",
          // url: "/purchase/settled/list",
          url: "/supplier/based/paymenthistory",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            page: this.page,
            count: 20,
            supplierId: this.$route.query.id,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status == true) {
              // this.settledList = response.data.data;
              this.settledList =response.data.ItemPaymentDetails
              this.Pagelength = Math.ceil(response.data.count / 20);
            } else {
              this.snackbar = true;
              this.msg = response.data.msg;
            }
  
            // this.msg = response.data.msg;
  
            // this.snackbar = true;
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      },
      formatDate(item) {
        var dt = new Date(item);
        var day = dt.getDate();
        var year = dt.getFullYear();
        // var hours = dt.getHours();
        // var minutes = dt.getMinutes();
        dt = dt.toString();
        // var ampm = hours >= 12 ? "pm" : "am";
        // hours = hours % 12;
        // hours = hours ? hours : 12;
        // minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime =
          day +
          " " +
          dt.slice(4, 7) +
          " " +
          year
          //  +
          // " , " +
          // hours +
          // ":" +
          // minutes +
          // " " +
          // ampm;
  
        return strTime;
      },
    },
  
  };
  </script>
    